<template>
  <div class="managingPeople hp100 bbox ">
    <el-card>
      <div class="padTop">
        <div class="topTitle">
          <h2>主页库背景设置</h2>
          <div class="textDelete">
            <el-button type="primary" @click="pictureUpload"><i class="el-icon-upload2"></i> 上传</el-button>
          </div>
        </div>
      </div>
      <div class="padBtm">
        <el-image class="elImage" :src="propertyValue">
        </el-image>
      </div>
    </el-card>

    <!-- 上传图片 -->
    <el-dialog title="背景上传" :visible.sync="pictureDialog" width="30%">
      <el-form ref="videoForm" :model="form" label-width="120px">
        <el-form-item label="背景图片上传：">
          <el-upload class="avatar-uploaderVideo" :action="$uploadURL" :show-file-list="false"
            :on-success="pictureSuccess" :on-remove="pictureRemove">
            <img v-show="form.fileUrl" :src="form.fileUrl" class="avatarVideo">
            <div v-show="!form.fileUrl">
              <i class="el-icon-plus avatar-uploader-iconVideo"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePicture">取 消</el-button>
        <el-button type="primary" @click="submitPicture">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictureDialog: false,
      propertyValue: '',
      form: {
        fileUrl: ''
      }
    };
  },
  created() {
    this.getPadPicture()
  },
  methods: {
    // 上传
    pictureUpload() {
      this.pictureDialog = true
    },
    // 关闭表单
    closePicture() {
      this.pictureDialog = false
    },
    // 确定按钮
    submitPicture() {
      var that = this
      that.pictureDialog = false
      const data = {}
      data.id = '2'
      data.coverImage = that.form.fileUrl
      that.$http.post('/coverConfig/update', data).then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
          that.getPadPicture()
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      })

    },
    // 图片上传成功
    pictureSuccess(val) {
      console.log(val);
      this.form.fileUrl = val.data.url
    },

    // 删除图片
    pictureRemove(res, file, fileList) {
      // console.log(res);
      const index = this.form.fileUrl.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.form.fileUrl.splice(index, 1);
    },

    // 获取封面图片
    getPadPicture() {
      var that = this
      that.$http.post('/coverConfig/queryOne', { id: '2' }).then(function (response) {
        // console.log(response);
        if (response.data.code == 200) {
          that.propertyValue = response.data.data.coverImage
          // that.$notify.success({
          //     title: "提示",
          //     message: "获取数据成功",
          //     showClose: true,
          //   });
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
  }
}

.topTitle {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;

  h2 {
    margin: 0;
  }
}

.padBtm {
  max-width: 889px;
  max-height: 500px;
  margin-top: 20px;
  padding: 0 50px;
  margin: 0 auto;

  .elImage {
    display: block;
    // max-width: 100%;
    height: 500px;
    object-fit: cover;
    margin: 0 auto;
  }
}

.avatar-uploaderVideo ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploaderVideo .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-iconVideo {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 170px;
  line-height: 170px;
  text-align: center;
}

.avatarVideo {
  width: 200px;
  height: 170px;
  display: block;
}
</style>
